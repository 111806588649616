import { none, State, Immutable } from "@hookstate/core"
import { Validation, ValidationError } from "@hookstate/validation"
import branding, { Branding, ValidationMessagesConfiguration, ErrorMessagesConfiguration } from "../branding/branding"
import { isNumeric } from "../utils"

type Identifiable = {
    id: string
}

export function addElement<T>(elementStates: State<T[]>, newElement: T) {
    const elementIndex = elementStates.get().findIndex((element) => (element as any)?.id === newElement)

    if (elementIndex >= 0) {
        elementStates[elementIndex].set(newElement)
        return
    }

    if (elementStates.get().length === 0) {
        elementStates.set([newElement])
        return
    }

    elementStates.merge([newElement])
}

export function removeElementById<T extends Identifiable>(elementStates: State<T[]>, elementId: string) {
    const elementIndex = elementStates.get().findIndex((element) => element.id === elementId)
    if (elementIndex >= 0) {
        elementStates[elementIndex].set(none)
    }
}

export function getElementFieldValue<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return elementState[fieldName].get() ?? undefined
}

export function getElementLocalizedName(element: any, language: string, property: string) {
    return language === "en" ? element[property] : element.localized[language][property]
}

export function makeSetHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        elementState[fieldName].set(event.target.value)
    }
}

export function makeCheckHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        elementState[fieldName].set(event.target.checked)
    }
}

export function makeSetHandlerPlain<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (value: unknown) => {
        elementState[fieldName].set(value)
    }
}

export function makeValidationHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    // return () => {
    //     if (!elementState[fieldName]) {
    //         console.log("ValidationHandler - missing field from state", elementState, fieldName, elementState[fieldName], elementState.promised)
    //     }
    //     return elementState[fieldName].errors()
    // }
    return () =>
        elementState &&
        elementState[fieldName as keyof State<T, Validation>] &&
        elementState[fieldName as keyof State<T, Validation>].errors()
}

export function getEntityCardTitle(entity: any, lang: string): string {
    if (entity.title) {
        if (lang === "De") {
            return entity.titleDe.get() || entity.title.get()
        }

        return entity.title.get() || entity.titleDe.get()
    }

    if (lang === "De") {
        return entity.nameDe.get() || entity.name.get()
    }

    return entity.name.get() || entity.nameDe.get()
}

export function hasError(lang: string, validationError: ValidationError) {
    const fieldName = validationError.path ? (validationError.path[validationError.path.length - 1] as string) : ""

    if (isNumeric(fieldName)) return true

    if (lang === "En") {
        return !fieldName.endsWith("De")
    }
    return fieldName.endsWith(lang)
}

export function hasErrors(lang: string, entityState?: State<any, Validation>) {
    if (entityState) {
        const validationErrors = entityState.errors()
        // console.log(validationErrors)
        return validationErrors.some((validationError) => hasError(lang, validationError))
    }
    return false
}

export function hasErrorsAll(entityState?: State<any, Validation>) {
    return hasErrors("En", entityState) || hasErrors("De", entityState)
}

export function translateValidationErrors(errors: readonly ValidationError[], branding: Immutable<Branding>): string {
    const messageKey = errors && errors.length > 0 ? errors[0].message : ""
    const errorMessage = branding.configuration.validationMessages[messageKey as keyof ValidationMessagesConfiguration]
    return errorMessage || messageKey
}

export function translateErrors(error: string, branding: Immutable<Branding>): string {
    const messageKey = error || ""
    const errorMessage = branding.configuration.errorMessages[messageKey as keyof ErrorMessagesConfiguration]
    return errorMessage || messageKey
}

export function truncateWithEllipsis(str: string, maxLength: number) {
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str
}

export function capitalizeFirstCharacter(str: string) {
    if (typeof str !== "string" || str.length === 0) {
        return str
    }

    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1)
}

export function isContentLanguageAvailable(language: string): boolean {
    return branding.contentLanguages.indexOf(language) > -1
}

export function getDefaultContentLanguage(): string {
    const contentLanguages = branding.contentLanguages

    return contentLanguages.length > 0 ? capitalizeFirstCharacter(contentLanguages[0]) : "En"
}
